import Homepage from './Homepage'
import './styles/linkModal.css';


function App() {
    return (
        <div className="App">
            {/* <Modal open={open} setOpen={setOpen} /> */}
            <Homepage/>
        </div>
    );
}

export default App;
