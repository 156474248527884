import React, { useState } from 'react';
import styled, { css } from "styled-components";
import arrow from '../../assets/arrow2.png';
import zk from '../../assets/zk.png';
import linea from '../../assets/linea.png';
import op from '../../assets/op.png';
import star from '../../assets/star.png';
import base from '../../assets/base.png';
import scroll from '../../assets/scroll.png';
import manta from '../../assets/manta.svg';
import kroma from '../../assets/kroma.jpg';
import mantle from '../../assets/mantle.png';
import blast from '../../assets/blast.svg';

import arb from '../../assets/new/arb-tld-logo-colored.jpg';
import bnb from '../../assets/new/bnb-tld-logo-colored.jpg';
import zeta from '../../assets/new/zeta-logo-main.webp';
import mode from '../../assets/new/mode-logo-design.webp';
import sei from '../../assets/new/sei-logo.png';
import zk1 from '../../assets/new/zkf-logo.webp';

import edgeless from '../../assets/new/edgeless-logo.webp';
import gno from '../../assets/new/gno-logo.jpg';
import ll from '../../assets/new/ll-logo.webp';
import cake from '../../assets/new/cake-nft-main.webp';
import taiko from '../../assets/new/taiko-logo-main.webp';




const Container = styled.div` 
position:absolute ;
right:0px ;
top:65px;
width:auto ;
min-width:700px;
height: auto;
background: #1d1d1d;
box-shadow: -2px 5px 10px 0px rgba(0,0,0,1);
font-family: 'fontspring-regular';
padding:var(--modal_padding) ;
// border-radius:8px ;
z-index:999 !important;

&>div:not(:last-child){
    margin-bottom:10px ;
}
`
const Trangle = styled.div` 
    position:absolute ;
    right:38px ;
    top:-15px;
    width: 0;
    height: 0;
    border: 15px solid;
    border-color: #1d1d1d transparent transparent #1d1d1d;
    transform:rotate(45deg) ;
    border-radius:3px ;
`
const list = [
    {
        id: 1,
        img: star,
        title: 'Star Name Service',
        link: 'https://app.sns.so/',
        isShow: false,
    },
    {
        id: 2,
        img: zk,
        title: 'zkSync Name Service',
        link: 'https://app.zkns.domains/',
        isShow: false,
    },
    {
        id: 3,
        img: linea,
        title: 'Linea Name Service',
        link: 'https://app.lineans.domains/',
        isShow: false,
    },
    {
        id: 4,
        img: op,
        title: 'Optimism Name Service',
        link: 'https://app.opns.domains/',
        isShow: false,
    },
    {
        id: 5,
        img: base,
        title: 'Base Name Service',
        link: 'https://app.basens.domains/',
        isShow: false,
    },
    {
        id: 6,
        img: scroll,
        title: 'Scroll Name Service',
        link: 'https://app.scrollns.com/',
        isShow: false,
    },
    {
        id: 7,
        img: manta,
        title: 'Manta Name Service',
        link: 'https://app.mantans.org/',
        isShow: false,
    },
    {
        id: 8,
        img: kroma,
        title: 'Kroma Name Service',
        link: 'https://app.kromans.domains/',
        isShow: false,
    }, {
        id: 9,
        img: mantle,
        title: 'Mantle Name Service',
        link: 'https://app.mantlens.domains/',
        isShow: false,
    },
    // {
    //     id: 10,
    //     img: blast,
    //     title: 'Blast Name Service',
    //     link: 'https://app.blastns.org/',
    //     isShow: false,
    // }
]
const newlist = [
    {
        id: 1,
        img: arb,
        title: '.arb Name Service',
        link: 'https://space.id/tld/2?inviter=0x3448f1cb3d8085ae8000644022ab9bda4b46b10b',
        isShow: false,
    },
    {
        id: 2,
        img: bnb,
        title: '.bnb Name Service',
        link: 'https://space.id/tld/1?inviter=0x3448f1cb3d8085ae8000644022ab9bda4b46b10b',
        isShow: false,
    },
    {
        id: 3,
        img: zeta,
        title: '.zeta Name Service',
        link: 'https://space.id/tld/11?inviter=0x3448f1cb3d8085ae8000644022ab9bda4b46b10b',
        isShow: false,
    },
    // {
    //     id: 3,
    //     img: mode,
    //     title: 'Mode Name Service',
    //     link: 'https://space.id/tld/6',
    //     isShow: false,
    // },
    // {
    //     id: 4,
    //     img: sei,
    //     title: 'Sei Name Service',
    //     link: 'https://sei.space.id/',
    //     isShow: false,
    // },
    // {
    //     id: 5,
    //     img: zk1,
    //     title: 'ZKFair',
    //     link: 'https://space.id/tld/8',
    //     isShow: false,
    // },
    // {
    //     id: 6,
    //     img: cake,
    //     title: 'PancakeSwap',
    //     link: 'https://space.id/tld/7',
    //     isShow: false,
    // },
    // {
    //     id: 7,
    //     img: gno,
    //     title: 'Genome',
    //     link: 'https://space.id/tld/14',
    //     isShow: false,
    // },

    // {
    //     id: 8,
    //     img: ll,
    //     title: 'LightLink Name Service',
    //     link: 'https://space.id/tld/9',
    //     isShow: false,
    // }, {
    //     id: 9,
    //     img: taiko,
    //     title: 'DotTaiko Name Service',
    //     link: 'https://space.id/tld/16',
    //     isShow: false,
    // },
    // {
    //     id: 10,
    //     img: edgeless,
    //     title: 'Tomo Name Service',
    //     link: 'https://space.id/tld/10',
    //     isShow: false,
    // }
]

export function LinkModal(props) {
    const { open } = props;
    const [linkData, setLinkData] = useState(list);
    const [NewlinkData, setNewLinkData] = useState(newlist);




    function onDisplay(id) {

        setLinkData(x => {
            const array = JSON.parse(JSON.stringify(x));
            for (let i = 0; i < array.length; i++) {
                if (array[i].id === id) {
                    array[i].isShow = !array[i].isShow;
                } else {
                    array[i].isShow = false;
                }
            }
            return array;
        })
    }



    return (
        <Container className='domainBox' style={{ display: open ? "block" : 'none' }} onClick={(e) => { e.stopPropagation() }}>
            <Trangle />
            <div className='domainPartner' style={{ display: 'inline-block', verticalAlign: 'top' }}>
            <div className='partnerDomian'>Partner</div>

                {
                    NewlinkData.map((v, index) => {
                        return <LinkItem item={v} key={index} onDisplay={onDisplay} />
                    })
                }

            </div>

            <div style={{ display: 'inline-block', width: '60px' }}></div>
            <div style={{ display: 'inline-block' }}>
                {
                    linkData.map((v, index) => {
                        return <LinkItem item={v} key={index} onDisplay={onDisplay} />
                    })
                }
            </div>
        </Container>
    )
}


const Item = styled.div` 
    width: var(--link_item_width);
    background: #2D2D2D;
    box-shadow: -2px 5px 10px 0px rgba(0,0,0,1), 0px 2px 4px 0px rgba(0,0,0,0.5);
    border-radius: 10px;
    // width:50%;
    margin-top:10px;
    // width:96%;
    @media screen and (max-width:360px){
        --link_item_width:268px;
    }
`

const TitleAndLogo = styled.div`
    width:100% ;
    height:var(--link_item_height);
    display:flex ;
    align-items:center ;
    padding-left:13px ;
    cursor: pointer;
    #Manta{
        padding:4px ;
    }
`

const LogoBox = styled.div` 
    width:30px ;
    height:30px ;
    margin-right:var(--logo_margin-right);
    border-radius:50% ;
    display:flex; 
    justify-content:center;
    align-items:center;
    overflow:hidden;

    
`

const Logo = styled.img`
    width:100%;
    height:100%;

    ${props => props.id === 9 && css` 
        width:20px ;
        height:20px ;
    `}

    ${props => props.id === 10 && css` 
        width:18px ;
    `}
`

const Text = styled.span` 
display:inline-block ;
font-family: 'fontspring-regular';
font-size: 18px;
white-space: nowrap;
color: #FFFFFF;
height:auto ;
@media screen and (max-width:360px){
    font-size:16px ;
}
`

const ViewNow = styled.div` 
 height:var(--link_item_height);
 line-height:var(--link_item_height);
 padding-right: 23px;
   
   main{
    cursor: pointer;
    display:flex ;
    justify-content:flex-end ;
    align-items:center ;
   }
`

const Arrow = styled.img` 
/* position: absolute;
right:0 ; */
width:var(--arrow_width) ;
height:var(--arrow_width) ;
margin-left:8px ;
`
const TextTip = styled.span` 
/* position:absolute ; */
color: #FFFFFF;
font-size: 16px;
opacity:0.4 ;
right: var(--text_to_right_distance);

@media screen and (max-width:360px){
    font-size:14px ;
}
`
function LinkItem(props) {
    const { onDisplay } = props;
    const { id, img, title, link, isShow } = props.item;

    function changeLink(e) {
        e.stopPropagation();
        window.location.href = link;
    }
    return (
        <Item style={{ background: isShow ? "#2D2D2D" : "#2D2D2D" }} display={isShow} onClick={(e) => { e.stopPropagation() }}>
            <div className='
            myDomain' onClick={(e) => changeLink(e)}><TitleAndLogo onClick={() => onDisplay(id)}>
                    <LogoBox id={title.split(' ')[0]} >
                        <Logo src={img} id={id} />
                    </LogoBox>
                    <Text>{title}</Text>
                </TitleAndLogo>
                {/* <ViewNow style={{ display: isShow ? "block" : "none" }}>
                    <main onClick={(e) => changeLink(e)}>
                        <TextTip>View now </TextTip>
                        <Arrow src={arrow} />
                    </main>
                </ViewNow> */}
            </div>
            <div></div>
        </Item>
    );
}
